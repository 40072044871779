.quote {
  lost-flex-container: row;
  background: var(--color-white);
  margin-bottom: 3rem;

  @media (--S_and_up) {
    margin-bottom: 4rem;
  }

  &__content {
    padding: 6rem var(--page-spacing--up_to_S) 4rem;
    .body--home & {
      display: none;

      &--visible {
        display: block;
      }
    }

    @media (--S_and_up) {
      padding: 15rem var(--page-spacing--S_and_up) 10rem;
      lost-column: 8/12;
      lost-offset: 2/12;
    }
  }

  &__text {
    @media (--S_and_up) {
      @apply --text-headline;
      line-height: 4.5rem;
    }
  }
}
