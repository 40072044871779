@import "properties.css";

.foundation {
  margin: 4.5rem 0;
}

.references {
  margin-bottom: 4.5rem;

  &__headline {
    @apply --text-headline;
    margin: 4.5rem 0 0;
    padding: 1.25rem 3.5rem;
    background-color: var(--color-blue);
    color: var(--color-white);

    @media (--S_and_up) {
      padding-left: 0;

      .headline__inner {
        lost-offset: 1/12;
      }
    }
  }
}
