
@import "member.css";

.foundation {
  lost-flex-container: column;
  @media (--S_and_up) {
    lost-flex-container: row;
  }
}

.foundation .tile {
  display: flex;
  background-size: cover;
  background-position: center;
  @media (--S_and_up) {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
}

.foundation .tile .text {
  padding-right: 3.5rem;
  padding-bottom: 3.5rem;
  padding-left: 3.5rem;
  color: #fff;
  background: linear-gradient(
    rgba(0,140,204,0.8), 
    rgba(0,140,204,1)
  );
  width: 100%;
}

.foundation .tile .tile__headline {
    margin-left: 0 !important;
}
.foundation .tile a {
  color: #fff;
}