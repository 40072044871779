@import "layout.css";
@import "layout.css";
@import "icon-list.css";

.footer {
  color: var(--color-white);

  &__content {
    position: relative;
    z-index: var(--zindex-content);
    padding: 6rem var(--page-spacing--up_to_S) 3.5rem;

    @media (--S_and_up) {
      padding: 15rem var(--page-spacing--S_and_up) 3.5rem;
      lost-column: 10/12;
      lost-offset: 2/12;
    }
  }

  &__textblock {
    margin-bottom: 5rem;

    p {
      margin: 0;
    }
  }

  &__buttonlist {
    margin-bottom: 7rem;

    @media (--S_and_up) {
      margin-top: 9.5rem;
      margin-bottom: 12rem;
    }
  }

  &__button {
    display: block;
    margin-bottom: 3rem;

    @media (--S_and_up) {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 4rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .fineprint__link {
    color: var(--color-white);
    font-weight: bold;
  }

  .fineprint__text {
    p { margin: 0; }

    @apply --text-small;

    @media (--S_and_up) {
      display: inline-block;
      margin-left: 2rem;
    }
  }
}
