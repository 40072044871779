@import "properties.css";

.heading {
  color: var(--color-white);
  @apply --text-headline;
  margin: 0;

  &.heading--banner {
    @apply --text-banner;
  }

  &.heading--subheading {
    font-weight: normal;
  }
}
