@import "member.css";

.teaser__container {
  lost-flex-container: column;

  @media (--S_and_up) {
    lost-flex-container: row;
  }
}

.teaser {
  position: relative;
  display: block;
  min-height: 40rem;
  flex: 1 0 100vw;
  margin-bottom: 3rem;

  @media (--S_and_up) {
    lost-column: 6/12;
    margin-bottom: 4rem;
    min-height: 60rem;
  }

  &__box {
    @apply --box-styles;

    @media (--M_and_up) {
      right: 40%;
      bottom: 10%;
      left: 12%;
    }
  }
}
