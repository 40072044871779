.pager {
  margin: 5rem 3.5rem;
  display: flex;

  @media (--S_and_up) {
    margin: calc(-2.5% + 5rem) 2.5% 5rem;
  }

  &__next {
    margin-left: auto;
    align-self: flex-end;
  }

  .button {
    color: var(--color-blue);
    border-color: var(--color-blue);

    &:hover {
      background-color: color(var(--color-blue) alpha(-90%));
    }

    &:active {
      color: var(--color-white);
      background-color: var(--color-blue);
    }
  }
}
