@import "comments.css";

:root {
  --genericStyles {
    p {
      img {
        max-width: 100%;
      }
    }

    ul,
    ol {
      padding-left: 3rem;
    }

    figure,
    div.highlight {
      margin: 0;
      padding: 1rem 3rem;
      border-left: 1rem solid var(--color-blue);
      background-color: var(--color-lightgrey);
      pre,
      pre.highlight {
        margin: 0;
        overflow: auto;
      }
    }
    code {
      @apply --font-monospace;
      font-size: .9em;
      word-wrap: break-word;
      background-color: var(--color-lightgrey);
    }
    a {
      word-wrap: break-word;
    }

    .player {
      position: relative;
      display: block;
      padding-bottom: 56.25%;
      height: 0;
      iframe {
        position: absolute 0;
        width: 100%;
        height: 100%;
      }

    }

    blockquote {
      border-left: 1rem solid var(--color-blue);
      margin-left: 0;
      padding-left: 3rem;
    }
  }
}

.post {
  padding-top: 5%;
  background-color: var(--color-white);

  &--no-bg {
    background: none;
  }

  @media (--S_and_up) {
    font-size: 1.2em;
    line-height: 1.6em;
  }

  &__meta {
    margin-bottom: 0;
  }

  &__header,
  &__content {
    margin: 0 auto;
    max-width: 130rem;
    padding: 0 3.5rem;
  }

  &__header {
    margin-bottom: 2em;

    .post__title {
      margin-top: 1rem;
      margin-bottom: 0;
    }
  }

  &__content {
    padding-bottom: 10rem;
  }

  &__author {
    margin-top: 10rem;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply --text-line-height-headline;
    margin-top: 8rem;
  }

  @apply --genericStyles;
}
