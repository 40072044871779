@import "slider-controls.css";

:root {
  --box-styles {
    position: absolute;
    right: var(--page-spacing--up_to_S);
    bottom: 3.5rem;
    left: var(--page-spacing--up_to_S);

    @media (--S_and_up) {
      right: 14rem;
    }
  }
}

.member__list {
  margin-top: 4rem;
  lost-flex-container: column;

  @media (--up_to_S) {
    &.peppermint-inactive {
      .member {
        display: none;

        &:first-child {
          display: block;
        }
      }
    }
  }

  @media (--S_and_up) {
    lost-flex-container: row;
    margin-bottom: 4rem;
  }

  .member {
    position: relative;
    background-size: cover;
    background-position: center 20%;
    min-height: 53rem;
    flex: 1 0 100vw;

    @media (--S_and_up) {
      display: block;
      lost-column: 4/12 0 0;
      min-height: 60rem;
    }

    @media (--S_to_M) {
      lost-column: 6/12 0 0;
    }

    &__box {
      @apply --box-styles;
    }
  }
}
