.categories {
  margin-top: 3rem;

  &--small {
    & .category {
      @apply --button--blue--small;
    }
  }
}

.category {
  @apply --button--blue;
  margin-bottom: 0.75rem;
}
