.testimonials {
  margin-top: 4.5rem;
  lost-flex-container: row;

  .quote__wrapper {
    .body--work & {
      display: none;

      &--visible {
        display: block;
      }
    }

    @media (--S_to_M) {
      &:last-child {
        padding-bottom: 10rem;
      }
    }

    @media (--M_and_up) {
      lost-column: 6/12;
      lost-flex-container: row;
    }
  }

  .quote__content {
    @media (--S_to_M) {
      lost-column: 8/12;
      lost-offset: 2/12;
      padding-top: 10rem;
      padding-bottom: 2rem;
    }

    @media (--M_and_up) {
      lost-column: 8/12;
      lost-offset: 2/12;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
