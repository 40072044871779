.headline {
  display: block;
  margin: 2em 3.5rem 2em;

  @media (--S_and_up) {
    margin-left: 2.5%;
    margin-right: 2.5%;
  }

  &__text {
    margin: 0;
    line-height: 1.2em;
  }
}
