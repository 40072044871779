.list {
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    &--rule {
      @apply --line;
      padding-top: 2rem;
      padding-bottom: 2rem;

      &:last-child {
        @apply --line-reset;
      }
    }

    &--icon {
      padding-left: 3.5rem;
      margin-bottom: 1.5rem;

      .svg {
        width: 2.5rem;
        height: 2.5rem;
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        margin-right: 1rem;
        margin-left: -3.5rem;
      }
    }
  }

  &__item-title {
    margin: 0;
  }

  &__item-content {
    margin-top: .5rem;
    margin-bottom: 0;
  }
}
