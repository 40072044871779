.banner-box {
  padding: 3.5rem 4rem 3.5rem 3rem;
  background: var(--color-yellow);
  box-shadow: 0 4px 4px 0 rgba(0,0,0,.33);

  &:hover {
    text-decoration: none;
  }

  &__headline {
    position: relative;
    padding-right: 3rem;
    @apply --text-copy;
    color: var(--color-blue);
    font-weight: bold;
    margin: 0;

    ^&:hover & {
      text-decoration: underline;

      &--heading {
        text-decoration: none;
      }
    }

    &--heading {
      @apply --text-headline;
      margin-bottom: 2rem;
    }
  }

  &__content {
    display: block;
    color: var(--color-black);
    margin-bottom: .5rem;
  }

  .button {
    margin-top: 3rem;
  }

  .svg--arrow-right {
    position: absolute;
    top: calc(3/8)rem;
    right: -1.75rem;
    width: 1.25rem;
    color: var(--color-darkgrey);
  }
}
