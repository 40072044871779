:root {
  --font-DIN {
    font-family: "DIN", "Neue Helvetica", Helvetica, Arial, sans-serif;
    text-rendering: optimizeLegibility;
  }

  --font-monospace {
    font-family: "Fira Code", monospace;
    text-rendering: optimizeLegibility;
  }

  /* text styles */
  --text-banner {
    font-size: 4.5rem;
    line-height: 6rem;
  }

  --text-headline {
    font-size: 3rem;
    line-height: 4rem;
  }
  --text-line-height-headline {
    line-height: 1.2em;
  }

  /* 18px/24px */
  --text-medium {
    font-size: 2.25rem;
    line-height: 3rem;
  }

  --text-copy {
    font-size: 2rem;
    line-height: 3rem;
  }

  --text-small {
    font-size: 1.625rem;
    line-height: 3rem;
  }

  --line {
    border-bottom: 1px solid var(--color-grey);
  }

  --line-reset {
    border-bottom-color: transparent;
  }

}
