/*!
This CSS resource incorporates links to font software which is the valuable copyrighted
property of Monotype Imaging and/or its suppliers. You may not attempt to copy, install,
redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
Imaging with any questions regarding Web Fonts:  http://www.fonts.com
*/
@font-face{
  font-family:"DIN";
  font-weight: 400;
  font-style: normal;
  src:url("/assets/fonts/6ceed230-b2b3-4422-b048-4aa11687430a.woff2") format("woff2"),url("/assets/fonts/80b0143f-6f0d-4dce-aafd-f3c81b85d177.woff") format("woff");
}
@font-face{
  font-family:"DIN";
  font-weight: 700;
  font-style: normal;
  src:url("/assets/fonts/55fa1cf8-baf8-4bf8-9718-b096b1dd231f.woff2") format("woff2"),url("/assets/fonts/4cadc2f9-fb32-4b99-b1f2-9ce6d68afbd5.woff") format("woff");
}
