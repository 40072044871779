.peppermint.peppermint-active {
  position: relative;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
}

.peppermint.peppermint-active .peppermint-slides {
  position: relative;
  overflow: hidden; /* clearfix */
  touch-action: manipulation;
  touch-action: pan-y;
  @media (--up_to_S) {
    height: 100%;
  }
}

.peppermint.peppermint-active .peppermint-slides > * {
  float: left;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

.peppermint.peppermint-active,
.peppermint.peppermint-active .peppermint-slides,
.peppermint.peppermint-active .peppermint-slides > * {
  transform: translate3d(0,0,0);
  backface-visibility: hidden;
}

.peppermint.peppermint-mouse .peppermint-slides {
  user-select: none;
  cursor: move;
  cursor: grab;
}

.peppermint.peppermint-mouse.peppermint-drag .peppermint-slides * {
  cursor: move;
  cursor: grabbing;
}
