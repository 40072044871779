.logo {
  color: var(--color-white);

  &--large {
    display: block;
    width: 66%;
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5rem;

    @media (--S_and_up) {
      margin-bottom: 12rem;
    }
  }
}
