@import "properties.css";
@import "colors.css";

html {
  font-size: 8px;
  background-color: var(--color-lightgrey);
}

body {
  @apply --font-DIN;
  @apply --text-copy;
  color: var(--color-darkgrey);
  touch-action: manipulation;
  -webkit-text-size-adjust: 100%;
}

a {
  color: var(--color-blue);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &[href^="tel:"] {
    color: inherit;
    background-color: inherit;
  }
}
