@import '../button.css';

.button {
  @apply --button;

  &--dark {
    color: var(--color-black);
    border-color: var(--color-black);
  }
}
