:root {
  --spacer {
    &:before {
      content: '';
      float: left;
      padding-top: 100%;
      @media (--S_and_up) {
        padding-top: 100%;
      }
    }
  }
}

body.body--slider {
  overflow: hidden;
}

.team-profiles {
  @media (--up_to_S) {
    height: 100%;
  }

  &__slider {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 11;
    background-color: rgba(0,0,0,.6);
    visibility: hidden;
    opacity: 0;

     @media (--S_and_up) {
       bottom: 0;
     }

    &--visible {
      visibility: visible;
      opacity: 1;

      @media (--up_to_S) {
        height: 100%;
      }
    }
  }

  &__wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;

    @media (--up_to_S) {
      height: 100%;
      width: auto;
    }
  }

  &__close {
    display: block;
    width: 5.5rem;
    height: 5.5rem;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    background: var(--color-black);
    color: var(--color-white);

    .svg {
      display: block;
      width: 2rem;
      height: 2rem;
      margin-left: 1.75rem;
      margin-top: 1.75rem;
    }
  }

  &__prev,
  &__next {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: calc(99.9999% * 1/12 - 2rem);
    transform: translateY(-50%);

    @media (--S_and_up) {
      right: 2.5%;
    }

    .svg {
      display: block;
      width: 4rem;
      height: 4rem;
      color: var(--color-white);

      > svg {
        -webkit-filter: drop-shadow(0 0 5px rgba(0,0,0,0.25));
      }
    }
  }

  &__prev {
    right: auto;
    left: calc(99.9999% * 1/12 - 2rem);

    @media (--S_and_up) {
      left: 2.5%;
    }
  }
}

.profile {
  lost-flex-container: row;
  background-color: var(--color-blue);
  height: 100%;

  @media (--up_to_S) {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__image {
    lost-column: 12/12;
    min-height: 50%;
    background-size: cover;
    background-position: center 10%;

    @apply --spacer;

    @media (--S_and_up) {
      lost-column: 6/12 0 0;
    }
  }

  &__details {
    lost-column: 12/12;
    lost-flex-container: row;
    align-items: center;
    padding-top: 5rem;
    padding-bottom: 5rem;

    @media (--S_and_up) {
      padding-top: 5rem;
      padding-bottom: 5rem;
      align-items: center;
      lost-column: 6/12 1 0;
    }
  }

  .details {
    @media (--up_to_S) {
      &__header .heading {
        @apply --text-medium;
      }
    }

    &__content {
      lost-column: 8/12;
      lost-offset: 2/12;
    }

    &__main {
      color: var(--color-white);
      margin-top: 4.5rem;
    }

    &__links {
      margin-top: 4.5rem;
      margin-bottom: 0;
    }
  }
}
