.slider-controls {
  height: 6.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  &--hidden {
    visibility: hidden;
  }

  @media (--S_and_up) {
    display: none;
  }

  &__prev,
  &__next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.5rem;
    height: 6.5rem;
    cursor: pointer;

    .svg {
      display: block;
      width: 1.25rem;
      height: 2.25rem;
    }
  }

  &__current {
    font-weight: bold;
  }
}
