.tile__container {
  color: var(--color-white);
  background-color: var(--color-blue);
  align-items: flex-start;

  &--multicolor,
  &--white {
    color: var(--color-darkgrey);
    background-color: var(--color-white);
  }

  &--uni {
    @media (--S_and_up) {
      padding: 11rem 0;
    }
  }

  @media (--S_and_up) {
    lost-flex-container: row;
  }
}

.tile {
  padding: 5rem var(--page-spacing--up_to_S) 6rem;

  .tile__container--multicolor & {
    &:nth-child(2) {
      background-color: var(--color-yellow);
    }
    &:nth-child(3) {
      color: var(--color-white);
      background-color: var(--color-blue);
    }
  }

  .tile__container--uni & {
    padding: 4rem var(--page-spacing--up_to_S) 4rem;
  }

  @media (--S_and_up) {
    lost-column: 6/12 0 0;
    lost-flex-container: row;
    padding: 8rem 0;

    .tile__headline,
    .tile__content {
      lost-column: 8/12;
      lost-offset: 2/12;
    }
  }

  &__headline {
    margin: 0;
    font-weight: bold;

    @media (--S_and_up) {
      @apply --text-headline;
    }
  }

  &__content {
    margin-top: 1rem;
    margin-bottom: 0;

    @media (--S_and_up) {
      margin-top: 2rem;
    }
  }
}
